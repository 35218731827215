module gmtApp.versionInfo {
    const moduleName = 'gmtApp.versionInfo';

    export type VersionConstants = {
        gitCommit: string,
        gitBranch: string,
        platform: string
    }
    angular.module(moduleName, [])
        .constant('GIT_INFO', (function (): VersionConstants {
        return {
            gitCommit: "01d5fe52",
            gitBranch: "master",
            platform: "placeholder"
        }
    })());
}